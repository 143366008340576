<template>
	<div class="cooperation">
		<div class="banner">
			<headers></headers>
			<img src="../../assets/image/workbanner.png" alt="">
		</div>
		<div class="breadcrumb box">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>合作洽谈</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="liaison box">
			<div class="titles">
				<div class="dv1">联系方式</div>
				<div class="dv2">contact information</div>
			</div>
			<div class="info">
				<div>
					<img src="../../assets/image/wwou1.png" alt="">
					<p class="p1">文字文字文字文字文字文字文字文字文字</p>
					<p class="p2">文字文字文字文字文字</p>
				</div>
				<div>
					<img src="../../assets/image/wwou2.png" alt="">
					<p class="p1">文字文字文字文字文字文字文字文字文字</p>
					<p class="p2">文字文字文字文字文字</p>
				</div>
				<div>
					<img src="../../assets/image/wwou3.png" alt="">
					<p class="p1">文字文字文字文字文字文字文字文字文字</p>
					<p class="p2">文字文字文字文字文字</p>
				</div>
			</div>
		</div>
		<div>
			<Maps :ad_yui="'西安市紫薇龙腾新世界'"></Maps>
		</div>
		<div class="form box">
			<div class="titles">
				<div class="dv1">保持联络</div>
				<div class="dv2">keep in contact</div>
			</div>
			<form action="">
				<el-input class="textarea"
				  type="textarea"
				  :rows="6"
				  placeholder="信息"
				  v-model="textarea">
				</el-input>
				<div>
					<el-input class="input" v-model="name" placeholder="姓名"></el-input>
					<el-input class="input" v-model="emali" placeholder="电子邮箱"></el-input>
				</div>
				<div>
					<el-input class="input" v-model="phone" placeholder="电话"></el-input>
					<el-input class="input" v-model="address" placeholder="地址"></el-input>
				</div>
				<p class="button">发送</p>
			</form>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import headers from '../../components/header.vue'
	import Foot from '../../components/Foot.vue'
	import Maps from '../../components/map.vue'
	import {
		Swipe,
		SwipeItem
	} from 'vant';
	export default {
		components: {
			headers,
			Foot,
			Maps
		},
		data() {
			return {
				url:'/address.html',
				textarea:'',
				name:'',
				emali:'',
				phone:'',
				address:''
			}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/less/cooperation_index";
</style>
