<template>
	<div class="amap-page-container">
		<el-amap :plugin="plugin" :amap-manager="amapManager" :zoomEnable="false" :zoom="zoom" :center="center" vid="amapDemo" ref="reds"
			style="width:100%;height:80vh" :events="events"></el-amap>
	</div>
</template>

<script>
	import {
		AMapManager
	} from "vue-amap";
	import gisGreen from '../assets/image/0d3264da8.png';
	let amapManager = new AMapManager();
	var map = amapManager.getMap();
	export default {
		props: ['type_cv', 'ad_yui'],
		data() {
			let _obj = this;
			return {
				amapManager,
				center: [106.705251, 26.600328],
				plugin: [{
					pName: "Scale",
					events: {
						init(instance) {
							console.log(instance);
						}
					}
				}],
				zoom: 16,
				events: {
					init(o) {
						_obj.createMap();
					}
				}
			};
		},
		created() {
			// 配置
		},
		mounted() {},
		methods: {
			createMap() {
				let o = amapManager.getMap();
				// o.on('click',function(e){
				// 	o.clearMap()
				// 	console.log(e)
				// 	var icon = new AMap.Icon({
				// 		// 图标尺寸
				// 		size: new AMap.Size(40, 46),
				// 	});
				// 	var marker = new AMap.Marker({
				// 		icon: icon,
				// 		position: [e.lnglat.lng, e.lnglat.lat],
				// 		offset: new AMap.Pixel(-10, -46),
				// 		title: 1,
				// 		text: 1,
				// 	});
				// 	marker.setMap(o);
				// })
				var path = [];
				var that = this;
				var geocoder = new AMap.Geocoder({});
				geocoder.getLocation(that.ad_yui, function(status, result) {
					if (status === 'complete' && result.geocodes.length) {
						var lnglat = result.geocodes[0].location
						that.center = [lnglat.lng, lnglat.lat]
						var marker = new AMap.Marker({
							icon: icon,
							position: [lnglat.lng, lnglat.lat],
							offset: new AMap.Pixel(-10, -46),
							title: 1,
							text: 1,
							label: {
								content: labelContent,
								offset: labelOffset
							}
						});
						marker.setMap(o);
					} else {
						log.error('根据地址查询位置失败');
					}
				});
				var icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(40, 46),
					image:gisGreen,
					// 图标所用图片大小
					imageSize: new AMap.Size(40, 46)
				});
				var labelContent = "<div style='background-color: #fff;padding: 20px;'><p style='color: #000;margin-bottom: 20px;'>西安志得网络技术有限公司</p><p style='color: #000;'>西安市紫薇·龙腾新世界（2401）</p></div>";
				var labelOffset = new AMap.Pixel(45, -70);
			}
		}
	};
</script>
<style>
	.amap-marker-label {
		border: 0px;
		background: rgba(255, 255, 255, 0);
		color: #fff;
		font-size: 17px;
		font-weight: bold;
		text-align: center;
	}
</style>
